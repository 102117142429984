.row {
    content: "";
    display : table;
    clear : both;
    padding-bottom: .25em;
  }

  .column {
    float : left;
    width : 20%;
    padding : .5em;
  }

.selected-player {
    background-color: black;
    color:whitesmoke;
    font-weight: bolder;
}

.match {
    margin: .5em;
    height: 7em;
    border: black;
    border-style:groove;
    background-color:silver;
}

.match-first {
    font-size:large;
    font-weight: bold;
    margin-top: .8em;
    padding-bottom: .2em;
}

.match-line {
    margin: .25em;
}

.hide {
    display : none;
}

.title {
    font-weight: bold;
    margin: .25em;
}
.available {
    background-color:mediumaquamarine;
}

.scheduled {
    background-color: antiquewhite;
}

img {
    border-radius: 50%;
}